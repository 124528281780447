import { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent } from '@ionic/react';
import MainHeader from '../components/MainHeader';
import Utilities from '../Utilities';
import './MemberDetails.css';
import { fetchMemberDetails } from '../providers/Members';

// interface Member {
//   name: string;
//   rank: string;
//   role: string;
//   profile_pic: string;
// }

const MemberDetails: React.FC = () => {
  const member_id = Utilities.getLastSegmentOfCurrentURL();
  const [this_member, setThisMember]: any = useState();
  const getMember = async () => {
    const fetched_member = await fetchMemberDetails( member_id );
    setThisMember(fetched_member);
  }
  useEffect(() => {
    getMember();
  }, [])
  return (
    <IonPage>
      {this_member &&
        <MainHeader this_path={`/members/${this_member.data.ID}`}/>
      }
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Member</IonTitle>
          </IonToolbar>
        </IonHeader>
        {this_member &&
          <IonCard>
            <IonImg src={this_member.data.profile_pic.url}/>
            <IonCardHeader>
              <IonCardSubtitle className={'member-rank text-' + this_member.data.rank}>{this_member.data.rank.replace('-', ' ')}</IonCardSubtitle>
              <IonCardTitle>{this_member.data.display_name}</IonCardTitle>
              {this_member.data.role && 
                <p>{this_member.data.role}</p>
              }
            </IonCardHeader>
            <IonCardContent>
              {this_member.data.bio}
            </IonCardContent>
          </IonCard>
        }
      </IonContent>
    </IonPage>
  );
};

export default MemberDetails;
