import { useState } from 'react'; 
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonButton } from '@ionic/react';
import MainHeader from '../components/MainHeader';
import './Settings.css';

const Settings: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [website, setWebsite] = useState<string>();
  const [bio, setBio] = useState<string>();
  return (
    <IonPage>
      <MainHeader this_path="/settings"/>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
        {username ? (
          <IonList>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Username</IonLabel>
              <IonInput value={username} onIonChange={e => setUsername(e.detail.value!)} readonly disabled></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Website</IonLabel>
              <IonInput value={website} onIonChange={e => setWebsite(e.detail.value!)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Bio</IonLabel>
              <IonTextarea value={bio} onIonChange={e => setBio(e.detail.value!)}></IonTextarea>
            </IonItem>
          </IonList>
        ) : (
          <>
            <p>You're logged out.</p>
            <IonButton href="/login" color="primary">Login</IonButton>
            <IonButton href="/register" color="primary" fill="outline">Register</IonButton>
          </>
        ) }
      </IonContent>
    </IonPage>
  );
};

export default Settings;
