
const API_BASE = 'https://api.scmoneyclub.com/wp-json/scmoneyclub';
const API_VERSION = '/v1';

type TypeMember = {
  data: any
};

export const fetchMembers = async () => {
  try {
    const res = await fetch(API_BASE + API_VERSION + '/members', { cache: 'no-cache' });
    const res_members = await res.json();
    return res_members;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const fetchMemberDetails = async ( member_id: string ) => {
  if (!member_id || member_id === '') return null;
  try {
    const res = await fetch(API_BASE + API_VERSION + '/members/' + member_id, { cache: 'no-cache' })
    const res_member = await res.json()
    return res_member;
  } catch (e) {
    console.error(e);
    return null;
  }
}
