import React, { useState, useEffect } from 'react';
import { IonPage, IonHeader, IonContent, IonTitle, IonToolbar, IonList, IonListHeader, IonItem, IonLabel, IonInput, IonToggle, IonRadio, IonCheckbox, IonItemSliding, IonItemOption, IonItemOptions, IonAvatar, IonButton, IonIcon, IonSkeletonText } from '@ionic/react';

import MainHeader from '../components/MainHeader';
import MemberItem from '../components/MemberItem';
import './Members.css';
import { fetchMembers } from '../providers/Members';

type TypeMember = {
  data: any
};

const Members: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [these_members, setTheseMembers] = useState<TypeMember[]>([]);

  const skeletonLoader = () => {
    let items = [];
    for (let i = 0; i < 10; i++) {
      items.push(<IonItem key={i}>
        <IonLabel>
          <IonSkeletonText animated style={{ width: '100%', height: "40px" }} />
        </IonLabel>
      </IonItem>)
    }
    return items;
  }

  const getMembers = async () => {
    const fetched_members = await fetchMembers();
    setTheseMembers(fetched_members);
    setIsLoading(false);
  };

  useEffect( () => {
    getMembers();
  }, [])

  // TODO: Use Skeleton Text: https://ionicframework.com/docs/api/skeleton-text
  return (
    <IonPage>
      <MainHeader this_path="/members"/>
      <IonContent fullscreen ion-margin-bottom>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Members</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonListHeader>
          <IonLabel>Founders</IonLabel>
          {/*<IonButton>See All</IonButton>*/}
        </IonListHeader>
        {isLoading ?
          skeletonLoader()
        : (
          <>
            {these_members.length > 0 &&
              <IonList>
                {these_members.map((member) => {
                  if (member.data.user_nicename === 'scmoneyclub' || member.data.rank.toLowerCase() !== 'founder') {
                    return;
                  }
                  return (
                    <MemberItem key={member.data.ID} member={member} />
                  )
                })}
              </IonList>
            }
            <IonListHeader>
              <IonLabel>Senior Investor</IonLabel>
              {/*<IonButton>See All</IonButton>*/}
            </IonListHeader>
            {these_members.length > 0 &&
              <IonList>
                {these_members.map((member) => {
                  if (member.data.user_nicename === 'scmoneyclub' || member.data.rank.toLowerCase() !== 'senior-investor') {
                    return;
                  }
                  return (
                    <MemberItem key={member.data.ID} member={member} />
                  )
                })}
              </IonList>
            }
            <IonListHeader>
              <IonLabel>Junior Investor</IonLabel>
              {/*<IonButton>See All</IonButton>*/}
            </IonListHeader>
            {these_members.length > 0 &&
              <IonList>
                {these_members.map((member) => {
                  if (member.data.user_nicename === 'scmoneyclub' || member.data.rank.toLowerCase() !== 'junior-investor') {
                    return;
                  }
                  return (
                    <MemberItem key={member.data.ID} member={member} />
                  )
                })}
              </IonList>
            }
            <IonListHeader>
              <IonLabel>Intern</IonLabel>
              {/*<IonButton>See All</IonButton>*/}
            </IonListHeader>
            {these_members.length > 0 &&
              <IonList>
                {these_members.map((member) => {
                  if (member.data.user_nicename === 'scmoneyclub' || member.data.rank.toLowerCase() !== 'intern') {
                    return;
                  }
                  return (
                    <MemberItem key={member.data.ID} member={member} />
                  )
                })}
              </IonList>
            }
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Members;
