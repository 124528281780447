import React, { useState } from 'react';
import { IonContent, IonHeader, IonInput, IonPage, IonTitle, IonToolbar, IonList, IonItemDivider, IonItem, IonLabel, IonButton } from '@ionic/react';
import MainHeader from '../components/MainHeader';
import './Login.css';
import { useDataProvider } from '../context/data';

const Login: React.FC = () => {
  const { data } = useDataProvider();
  const [eth_address, setETHAddress] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  // const [is_logged_in, setLoggedIn] = useState(false); // TODO: move this to context/data.tsx
  const loginHandler = () => {
    console.log(username, password);
    if (username !== '' && password !== '') {
      // setLoggedIn(true);
      data.isLoggedIn = true; // TODO: this isn't re-rendering state, we need a better service provider data manger for login
      data.currentUserID = 1; // TODO: create a real logins service
      setUsername(username);
      setPassword(password);
      console.log(username, password, data);
    }
  };
  const logoutHandler = () => {
    alert('TODO: Logout');
  };
  return (
    <IonPage>
      <MainHeader this_path="/"/>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          {!data.isLoggedIn ? (
              <>
                <IonList>
                  <IonItem>
                    <IonLabel position="floating">Ethereum Address</IonLabel>
                    <IonInput value={eth_address} type="text" onIonChange={e => setETHAddress(e.detail.value!)} required></IonInput>
                  </IonItem>
                  <IonItemDivider color="transparent">Or</IonItemDivider>
                  <IonItem>
                    <IonLabel position="floating">Username</IonLabel>
                    <IonInput value={username} type="text" onIonChange={e => setUsername(e.detail.value!)} required></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput value={password} type="password" onIonChange={e => setPassword(e.detail.value!)} required></IonInput>
                  </IonItem>
                </IonList>
                <IonButton onClick={loginHandler}>Submit</IonButton>
                <p><a href="#">Forgot password?</a></p>
                <p><a href="#">Need to create an account?</a></p>
              </>
            ) : (
              <>
                <p>You're logged in as {username}.</p>
                <IonButton color="primary" href="/profile">View Profile</IonButton>
                <IonButton color="primary" fill="outline" onClick={logoutHandler}>Logout</IonButton>
              </>
            )
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
