import { Redirect, Route } from 'react-router-dom';
import
  // React,
  { useState } from 'react';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { analytics, people, logoBitcoin, settings } from 'ionicons/icons';
import Login from './pages/Login';
import Analytics from './pages/Analytics';
import Members from './pages/Members';
import MemberDetails from './pages/MemberDetails';
import Prices from './pages/Prices';
import Settings from './pages/Settings';
import Profile from './pages/Profile/Profile';
import DataProvider from './context/data';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';
// import CoinSearch from './pages/CoinSearch/CoinSearch';
import CoinDetails from './pages/CoinDetails/CoinDetails';

const App: React.FC = () => {
  // TODO: Use React Context
  // const [is_logged_in, setLoggedIn] = useState(false); 
  return (
    <IonApp>
      <DataProvider>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/analytics">
                <Analytics />
              </Route>
              <Route exact path="/members/:id">
                <MemberDetails />
              </Route>
              <Route exact path="/members">
                <Members />
              </Route>
              <Route exact path="/prices/:coin_name">
                <CoinDetails />
              </Route>
              <Route exact path="/prices">
                <Prices />
              </Route>
              {/*
              <Route exact path="/prices/search">
                <CoinSearch />
              </Route>
              */}
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route exact path="/">
                <Login />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="analytics" href="/analytics">
                <IonIcon icon={analytics} />
                <IonLabel>Analytics</IonLabel>
              </IonTabButton>
              <IonTabButton tab="members" href="/members">
                <IonIcon icon={people} />
                <IonLabel>Members</IonLabel>
              </IonTabButton>
              <IonTabButton tab="prices" href="/prices">
                <IonIcon icon={logoBitcoin} />
                <IonLabel>Prices</IonLabel>
              </IonTabButton>
              <IonTabButton tab="settings" href="/settings">
                <IonIcon icon={settings} />
                <IonLabel>Settings</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </DataProvider>
    </IonApp>
  );
};

export default App;
