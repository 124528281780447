import React, { useState, useEffect } from 'react';
import { IonPage, IonHeader, IonContent, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg } from '@ionic/react';
import MainHeader from '../components/MainHeader';

const Analytics: React.FC = () => {
  return (
    <IonPage>
      <MainHeader this_path="/members"/>
      <IonContent fullscreen ion-margin-bottom>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Analytics</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>Members</IonCardSubtitle>
                  <IonCardTitle>10</IonCardTitle>
                </IonCardHeader>        
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>Venture Investments</IonCardSubtitle>
                  <IonCardTitle>4</IonCardTitle>
                </IonCardHeader>        
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonCard className="ion-no-margin-start">
          <IonCardHeader>
            <IonCardSubtitle>Assets under management</IonCardSubtitle>
            <IonCardTitle>500k</IonCardTitle>
          </IonCardHeader>        
        </IonCard>
        <IonCard>
          <IonImg src="https://panteracapital.com/wp-content/uploads/2021/04/Five-Orders-of-Magnitude.png"/>
          <IonCardHeader>
            <IonCardSubtitle>Orders Of Magnitude</IonCardSubtitle>
            <IonCardTitle>5</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
          It’s pretty freaky deaky to have two data sets go up five orders of magnitude and have their relationship be perfectly constant. For every million new users, the price of bitcoin rises $200.  It happened every time except for February 2016, when the price was slow to hit. I realize that sounds like a large caveat – but these relationships have held for a decade.  The compound annual growth rate (CAGR) of bitcoin has been 213% for more than ten years.
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Analytics;
