
export const saveOrRemoveFromFav = (newCoin: string) => {
  let favCoins:any = localStorage.getItem('favCoins');
  favCoins = !favCoins?[]: JSON.parse(favCoins);
  let isFavCoin = false;
  if (!favCoins.includes(newCoin)) {
    favCoins.push(newCoin);
    isFavCoin = true;
  } else{
    favCoins.splice(favCoins.indexOf(newCoin),1)
  }
  localStorage.setItem('favCoins', JSON.stringify(favCoins));
  return isFavCoin;
}

export const checkIsFavCoin = (coin_name: string) => {
  let favCoins: any = localStorage.getItem('favCoins');
  favCoins = !favCoins ? [] : JSON.parse(favCoins);
  return (favCoins.includes(coin_name)) ? true : false;
}