import React from 'react';

// const AuthContext = React.createContext({
// 	isLoggedIn: false
// });

interface IState {
  data: null | undefined | any;
  updateItem: (itemData: any, key: string) => Promise<void>;
}

const DataContext = React.createContext<IState | undefined>(undefined);

export const DataProvider: React.FC = ({ children }) => {
	const updateItem = (itemData: any, key: string) => {
	  return itemData[key] = itemData;
	};
	// the store object
	let state = {
	  data: {
	  	isLoggedIn: false,
	  	currentUser: null,
	  },
	  // addItem, // todo
	  updateItem,
	  // removeItem, // todo
	};
	// wrap the application in the provider with the initialized context
	return <DataContext.Provider value={state}>{children}</DataContext.Provider>;
};

// export default DataProvider;

export default DataProvider;
export const useDataProvider = () =>
  React.useContext<IState | undefined>(DataContext)!;