import { IonItem, IonAvatar, IonLabel, IonButton, IonIcon } from '@ionic/react';
import { person } from 'ionicons/icons';

interface MemberProps {
  member: {
    data: any 
  }
}

const MemberItem: React.FC<MemberProps> = ({member}: any) => {
  return (
    <IonItem key={member.data.id} className={'member-item member-rank-' + member.data.rank}>
      <IonAvatar slot="start">
        {member.data.profile_pic && member.data.profile_pic !== null ? (
          <img src={member.data.profile_pic.sizes.thumbnail}/>
        ) : (
          <IonIcon icon={person} />
        )}
      </IonAvatar>
      <IonLabel>
        <h3>
          <strong>{member.data.display_name}</strong>
        </h3>
        <p>{member.data.role}</p>
      </IonLabel>
      <IonButton href={`/members/${member.ID}`} color="primary" fill="outline" slot="end">View</IonButton>                
    </IonItem>
  );
};

export default MemberItem;
