
import { useContext } from 'react';
import { IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton, IonIcon, IonLabel, IonButton } from '@ionic/react';
import { person } from 'ionicons/icons';
import { useDataProvider } from '../context/data';

interface MainHeaderProps {
    this_path: string;
}   

const MainHeader: React.FC<MainHeaderProps> = ({ this_path }: any) => {
    const { data } = useDataProvider();
    return (
        <IonHeader>
            <IonToolbar>
                {this_path !== "/" && (
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" color="medium"/>
                    </IonButtons>
                )}
                {/* <IonTitle>MainHeader!!</IonTitle> */}
                {this_path !== "/" &&
                    <IonButtons slot="end">
                        {(!data.isLoggedIn) ? (
                            <IonButton href="/login" color="medium">
                                <IonIcon icon={person} />
                                <IonLabel>Login</IonLabel>
                            </IonButton>
                        ) : (
                            <IonButton href="/profile" color="medium">
                                <IonIcon icon={person} />
                                <IonLabel>Profile</IonLabel>
                            </IonButton>
                        )}
                    </IonButtons>
                }
            </IonToolbar>
        </IonHeader>
    );
};

export default MainHeader;
