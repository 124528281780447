// import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import MainHeader from '../../components/MainHeader';
import './Profile.css';

const Profile: React.FC = () => {
  return (
    <IonPage>
      <MainHeader this_path="/"/>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <p>Profile details go here.</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
