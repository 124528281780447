import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonNote, IonPage, IonRouterLink, IonRow, IonSearchbar, IonSkeletonText, IonTitle, IonToolbar, IonAvatar, IonSegment, IonSegmentButton } from '@ionic/react';
import { useEffect, useState, useContext } from 'react';
import { chevronForward, options, helpCircle } from 'ionicons/icons';
import { allCoins, getChart, getCoins, getTopMktCapFull } from '../providers/CryptoCompare';
import MainHeader from '../components/MainHeader';
import './Prices.css';

const Prices: React.FC = () => {
  const [coinsToSearch, setCoinsToSearch] = useState<any>({});
  const [searchFilter, setSearchFilter] = useState<any>({});
  const [coins, setCoins] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page,setPage] = useState(1);
  const [searchCoin,setSearchCoin] = useState("");
  const [selectedSegment,setSelectedSegment] = useState("all");

  useEffect(() => {
    getAllCoins();
    getCoinsForSearching();
  }, []);

  useEffect(() => {
    getAllCoins();
  }, [page]);

  const getAllCoins = ()=>{
    if(page == 1){
      setIsLoading(true);
    }
    getTopMktCapFull(page).then(res=>{
      if(page > 1){
        setCoins(coins.concat(res.data.Data));
      }else{
        setCoins(res.data.Data);
      }
      setIsLoading(false);
    });
  }
  const getCoinsForSearching = ()=>{
    allCoins().then(res=>{
      setCoinsToSearch(res.data.Data);
    });
  }
  const searchForCoin = (toSearch:string)=>{
    setSearchCoin(toSearch);
    if(!toSearch.trim()){
        setPage(1);
        getAllCoins();
        return;
    }
    let tempFIlteredCoins:any = [];
    let tempSaveFIlteredCoins:any = [];
    tempFIlteredCoins = Object.keys(coinsToSearch).filter((value:any) => new RegExp(toSearch.toUpperCase()).test(value));
    tempFIlteredCoins = tempFIlteredCoins.sort(function(a:string, b:string){return a.length - b.length});
    tempFIlteredCoins.slice(0, 200).map((c:any)=>{
      tempSaveFIlteredCoins.push(coinsToSearch[c])
    });
    setCoins(tempSaveFIlteredCoins);
  }
  const loadMoreCoins = ($event: CustomEvent<void>)=>{
    setPage(page+1);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const loadSkeleton = () => {
    let items = [];
    for (let i = 0; i < 10; i++) {
      items.push(<IonItem key={i}>
        <IonLabel>
          <IonSkeletonText animated style={{ width: '100%', height: "40px" }} />
        </IonLabel>
      </IonItem>)
    }
    return items;
  }
  const loadAllFav = ()=>{
    let favCoins:any = localStorage.getItem('favCoins');
    favCoins = !favCoins ? [] : JSON.parse(favCoins);
    let tempFIlteredCoins: any = [];
    let tempSaveFIlteredCoins: any = [];
    tempFIlteredCoins = Object.keys(coinsToSearch).filter((value:any) => favCoins.includes(value));
    tempFIlteredCoins = tempFIlteredCoins.sort(function(a:string, b:string){return a.length - b.length});
    tempFIlteredCoins.map( (c: any) => {
      tempSaveFIlteredCoins.push(coinsToSearch[c])
    });
    setCoins(tempSaveFIlteredCoins);
  }
  const changeSegment = (newSegment:any)=>{
    setSelectedSegment(newSegment);
    if(newSegment == "fav"){
      loadAllFav();
      return;
    }
    setPage(1);
    getAllCoins();
  }

  return (
    <IonPage>
      <MainHeader this_path="/prices" />
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Prices</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <IonButton>
          <IonIcon slot="icon-only" name={options}></IonIcon>
        </IonButton> */}
        <IonSearchbar value={searchCoin} onIonChange={e => searchForCoin(e.detail.value!)} onTouchCancel={e => searchForCoin("")}></IonSearchbar>
        {(searchCoin == "") ?
          <div className="ion-padding ion-no-padding-start">
            <IonSegment value={selectedSegment} onIonChange={e => {changeSegment(e.detail.value)}}>
             <IonSegmentButton value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="fav">
                <IonLabel>Favorites</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          : (
            <></>
          )
        }
        <IonList>
          {
            isLoading ? loadSkeleton() :
            coins.map((coin: any, key: any) => {
              return <IonItem key={coin.CoinInfo?coin.CoinInfo.Id:key} href={"/prices/"+(coin.CoinInfo?coin.CoinInfo.Name:coin.Symbol)+"?coinName="+(coin.CoinInfo?coin.CoinInfo.FullName:coin.CoinName)}>
                {(coin.CoinInfo) ?
                  <>
                    <IonAvatar slot="start">
                      {coin.DISPLAY ? (
                        <img src={'https://www.cryptocompare.com' + coin.DISPLAY.USD.IMAGEURL}/>
                      ) : (
                        <IonIcon icon={helpCircle}/>
                      )}
                    </IonAvatar>
                    <IonLabel>{coin.CoinInfo.FullName} - {coin.CoinInfo.Name}</IonLabel>
                    <IonLabel className="ion-text-end">{(!coin.DISPLAY?"":coin.DISPLAY.USD.PRICE)}</IonLabel>
                    <IonIcon icon={chevronForward} slot="end"></IonIcon>
                  </>
                :
                  <>
                    <IonAvatar slot="start">
                      {coin.ImageUrl ? (
                        <img src={'https://www.cryptocompare.com' + coin.ImageUrl}/>
                      ) : (
                        <IonIcon icon={helpCircle}/>
                      )}
                    </IonAvatar>
                    <IonLabel>{coin.CoinName} - {coin.Symbol}</IonLabel>
                    <IonIcon icon={chevronForward} slot="end"></IonIcon>
                  </>
                }
              </IonItem>
            })
          }
        </IonList>
        {(searchCoin == "" && selectedSegment == "all") ? (
          <IonInfiniteScroll threshold="100px" onIonInfinite={(e)=>{loadMoreCoins(e)}} disabled={loadingMore}>
            <IonInfiniteScrollContent loading-spinner="bubbles" loading-text="Loading more coins..." />
          </IonInfiniteScroll>
        ) : (
          <></>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Prices;
