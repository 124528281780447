import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonAvatar, IonNote, IonPage, IonRouterLink, IonRow, IonSearchbar, IonSkeletonText, IonTitle, IonToolbar, IonCard, IonCardContent, IonListHeader } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useLocation, useParams } from 'react-router';
import { star, starOutline, analyticsOutline, barChartOutline, pieChartOutline } from 'ionicons/icons';
import { allCoins, getChart, getCoin, getCoins, getTopMktCapFull } from '../../providers/CryptoCompare';
import { saveOrRemoveFromFav, checkIsFavCoin } from '../../providers/CoinFavorites';
import MainHeader from '../../components/MainHeader';
import './CoinDetails.css';

const CoinDetail: React.FC = () => {
  const [coin, setCoin] = useState<any>({});
  const [change24h, setChange24h] = useState<string>('');
  const [coinChart, setCoinChart] = useState<any>({});
  const [isFavCoin, setIsFavCoin] = useState(false);
  const { coin_name } = useParams<any>();
  
  useEffect(() => {
    getCoinData();
  }, []);

  const getCoinData = ()=>{
    getCoin(coin_name).then( (res) => {
      setCoin({
        DISPLAY: res.data.DISPLAY[coin_name].USD,
        RAW: res.data.RAW[coin_name].USD
      })
      const coin_change24Hour = res.data.DISPLAY[coin_name].USD.CHANGE24HOUR;
      const text_class = (parseFloat(coin_change24Hour.replace('$', '')) > 0) ? 'text-success' : 'text-danger';
      const change24h = '\
        <div class="' + text_class + ' text-right">\
          <strong class="d-block">' + coin_change24Hour + ' (24h)</strong>\
          <span>' + res.data.DISPLAY[coin_name].USD.CHANGEPCT24HOUR + '%</span>\
        </div>';
      const CHANGEPCT24HOUR = 
      setChange24h(change24h);
      getCoinChart();
      if ( checkIsFavCoin(coin_name) ) {
        setIsFavCoin(true);
      }
    }).catch(err=>{
      alert(err);
    })
  }

  const getCoinChart = ()=>{
    getChart(coin_name).then((res)=>{
      if(!res.data.Data){
        return;
      }
      const lineData = {
        labels: res.data.Data.map((d:any)=>{
          let date = new Date((d.time)*1000);
          return (date.getMonth()+1)+"/"+date.getDate()
        }),
        datasets: [
          {
            label: 'Price (30 Days)',
            fill: true,
            lineTension: 0.5,
            backgroundColor: 'rgba(78, 168, 222, 0.5)',
            borderColor: 'rgba(83, 144, 217, 1)',
            borderWidth: 2,
            data: res.data.Data.map((d:any)=>{return d.close})
          }
        ]
      };
      setCoinChart({data:lineData});
    });
  }

  const this_saveOrRemoveFromFav = (newCoin: string) => {
    setIsFavCoin( saveOrRemoveFromFav(newCoin) );
  }

  const GetCoinName = () => {
    let usp = new URLSearchParams(useLocation().search);
    if (usp.get("coinName")){
      return usp.get("coinName");
    }
    return coin.DISPLAY.FROMSYMBOL;
  }
  return (
    <IonPage>
      <MainHeader this_path="/members"/>
      <IonContent fullscreen class="ion-padding-bottom">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {coin.DISPLAY ?
              GetCoinName()
              :
                <IonSkeletonText animated style={{ width: '50px', height: "24px" }} />
              }
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonListHeader>
          <IonLabel>
            {coin.DISPLAY ? (
              coin.DISPLAY.PRICE
            ) : (
              <IonSkeletonText animated style={{ width: '70px', height: "32px" }} />
            )}
          </IonLabel>
          <IonButtons className="fav_button">
            <IonButton onClick={() => {this_saveOrRemoveFromFav(coin_name)}}>
              <IonIcon slot="icon-only" icon={isFavCoin==false?starOutline:star} />
            </IonButton>
          </IonButtons>
        </IonListHeader>
        {coinChart.data ?
          <Line className="ion-margin"
            type="Line"
            data={coinChart.data}
            options={{
              title:{
                display:false
              },
              legend:{
                display:false,
              }
            }}
          />
        :
          <IonSkeletonText animated style={{ width: '100%', height: "40vh" }} />
        }
        <IonCard>
          <IonCardContent className="coin-detail_change-details_content">
            {coin.DISPLAY ? (
              <>
                <div className="d-inline-flex align-items-center">
                  <img src={'https://www.cryptocompare.com' + coin.DISPLAY.IMAGEURL} className="coin-detail_image ion-margin-end"/>
                  <strong>{coin.DISPLAY.FROMSYMBOL}</strong>
                </div>
                <div className="d-inline-block" dangerouslySetInnerHTML={{ __html: change24h.replace("$ -","-$ ") }}/>
              </>
            ) : (
              <IonSkeletonText animated style={{ width: '100%', height: "24px" }} />
            )}            
          </IonCardContent>
        </IonCard>
        {/*<div className="ion-margin">
          <IonButton color="primary" onClick={() => {window.alert('Coming Soon!')}} style={{ width: '100%' }}>Trade</IonButton>
        </div>*/}
        <IonListHeader>
          <IonLabel>Market Stats</IonLabel>
        </IonListHeader>
        <IonList margin-bottom>
          <IonItem>
            <IonIcon slot="start" icon={analyticsOutline} color="secondary"/>
            <IonLabel>
              Market Cap
            </IonLabel>
            <IonNote slot="end">
              {coin.DISPLAY?coin.DISPLAY.MKTCAP:<IonSkeletonText animated style={{ width: '50px', height: "24px" }} />}
            </IonNote>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={barChartOutline} color="secondary"/>
            <IonLabel>
              Volume (24h)
            </IonLabel>
            <IonNote slot="end">
              {coin.DISPLAY ? (
                coin.DISPLAY.VOLUME24HOUR
              ) : (
                <IonSkeletonText animated style={{ width: '50px', height: "24px" }}/>
              )}
            </IonNote>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={pieChartOutline} color="secondary"/>
            <IonLabel>
              Circulating Supply
            </IonLabel>
            <IonNote slot="end">
              {coin.DISPLAY?coin.DISPLAY.SUPPLY:<IonSkeletonText animated style={{ width: '50px', height: "24px" }} />}
            </IonNote>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default CoinDetail;
