import axios from "axios";

export const allCoins = ()=>{
  return axios.get("https://min-api.cryptocompare.com/data/all/coinlist");
}

export const getCoins = (coins:Array<String>)=>{
  const coinlist = coins.join();
  return axios.get("https://min-api.cryptocompare.com/data/pricemultifull?fsyms="+coinlist+"&tsyms=USD");
}

export const getCoin = (coin:String)=>{
  return axios.get("https://min-api.cryptocompare.com/data/pricemultifull?fsyms="+coin+"&tsyms=USD")
}

export const getChart = (coin:String)=>{
  return axios.get("https://min-api.cryptocompare.com/data/histoday?fsym="+coin+"&tsym=USD&limit=30&aggregate=1");
}

export const getTopMktCapFull = (page:Number)=>{
	let fetch_url = 'https://min-api.cryptocompare.com/data/top/mktcapfull?limit=25';
	fetch_url += (page !== 1) ? '&page=' + page : '';
	fetch_url += '&tsym=USD';
  return axios.get(fetch_url);
}